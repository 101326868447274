/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Text2 } from 'components/design-system'
import { toast } from 'components/design-system/Toast/manager'

import useStyle from './style'
import { Disciplines, EmptyStateComponent, Loading, Resource, Segments } from 'components/common'
import { BusinessError } from 'navigation/BusinessError'
import { useStore } from 'store'
import { ReactComponent as CloeAvatar } from 'assets/cloe-avatar-with-files-stand-up.svg'
import { DownloadOutlined } from '@mui/icons-material'
import { IGetProgramContentResourceParams, getProgramContentResource } from 'services/program-content'
import { IDiscipline, IGradeTypeEnum, IResourceResponse, ISchoolDisciplinesResponse, getSchoolDisciplines } from 'services'
import { hasFeatureFlag } from 'components/feature-toggle/store'
import { ToggleFeaturesEnum } from 'components/feature-toggle/enabledFeatures'

enum ComponentState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
  READY = 'READY'
}

interface SegmentDiscipline {
  disciplines: IDiscipline[]
  id: number
  name: string
  order: string
  code: 'EI' | 'EF1' | 'EF2' | 'EM' | 'EM_IT' | 'multigrade'
}

export const ProgramContent: React.FC = () => {
  const classes = useStyle()
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { profile, session, school } = useStore()

  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [gradeTypeSelected, setGradeTypeSelected] = useState<number>(1)
  const [taughtDisciplines, setTaughtDisciplines] = useState<ISchoolDisciplinesResponse[]>([])
  const [stateMachine, setStateMachine] = useState<ComponentState>(ComponentState.LOADING)
  const [resource, setResource] = useState<IResourceResponse>()
  const [segmentDisciplines, setSegmentDisciplines] = useState<SegmentDiscipline[]>([])
  const [currentDiscipline, setCurrentDiscipline] = useState<IDiscipline | undefined>(undefined)

  const pedagogicMapLink = 'https://mapapedagogico.cloeapp.com/'

  useEffect(() => {
    setStateMachine(ComponentState.LOADING)

    if (!profile || !session || !school) {
      setStateMachine(ComponentState.ERROR)
      return
    }
    // Timeout para dar tempo de adicionar os dados no header
    setTimeout(() => { void getSubscriptionsTaughtDisciplines() }, 1000)
  }, [profile, session, school])

  useEffect(() => {
    setCurrentDiscipline(undefined)
    adjustAvailableDisciplines()
  }, [gradeTypeSelected, segmentDisciplines])

  useEffect(() => {
    const loadingResource = async () => {
      if (currentDiscipline) {
        await getResource()
      }
    }

    loadingResource()
  }, [currentDiscipline])

  const adjustAvailableDisciplines = () => {
    const isEI_EM_IN = [IGradeTypeEnum.EI, IGradeTypeEnum.EM_IT].includes(gradeTypeSelected) // EI and EM_IT should have All options only
    if (isEI_EM_IN) {
      setDisciplines([])
      return
    }

    const disciplines = segmentDisciplines?.find(segment => segment?.id === gradeTypeSelected)?.disciplines

    setDisciplines(disciplines ?? [])
  }

  const handlePDFDownload = () => {
    const pdfURL = resource?.medias?.[0].url

    if (!pdfURL) {
      toast.handler({
        content: t('Não foi possivel baixar o PDF, tente novamente mais tarde.'),
        duration: 5000,
        severity: 'error'
      })
      return
    }
    window.open(pdfURL, '_blank')
  }

  const handleDisciplineChange = (discipline: IDiscipline) => {
    setCurrentDiscipline(discipline)
  }

  const getSubscriptionsTaughtDisciplines = async () => {
    if (!school?.id) {
      setStateMachine(ComponentState.ERROR)
      return
    }

    const response = await getSchoolDisciplines(school.id)

    if (!response.success) {
      setStateMachine(ComponentState.ERROR)
      return
    }

    const filteredTaughtDisciplines = response.data.filter(d => d.code !== 'multigrade')

    setTaughtDisciplines(filteredTaughtDisciplines)

    const allTaughtDisciplines = filteredTaughtDisciplines.map(({ grades, ...segment }) => ({
      ...segment,
      disciplines: grades.map(grade =>
        grade.disciplines
      ).flat(2).sort((disciplineA, disciplineB) => disciplineA.name.localeCompare(disciplineB.name))
    }))

    const uniqueAllTaughtDisciplines = allTaughtDisciplines.map(({ disciplines, ...segment }) => {
      const includedDisciplines: number[] = []
      const uninqueDisciplines = disciplines.filter(discipline => {
        if (!discipline.id) return false

        const isAlreadyIncluded = includedDisciplines.includes(discipline.id)

        if (isAlreadyIncluded) return false

        includedDisciplines.push(discipline.id)

        return true
      })

      return {
        ...segment,
        disciplines: uninqueDisciplines
      }
    })

    setSegmentDisciplines(uniqueAllTaughtDisciplines)
  }

  const getResource = async () => {
    setStateMachine(ComponentState.LOADING)

    if (!gradeTypeSelected || !currentDiscipline) {
      setStateMachine(ComponentState.ERROR)
      return
    }

    const isAll = currentDiscipline.code === 'ALL'

    const props: IGetProgramContentResourceParams = {
      gradeType: gradeTypeSelected
    }

    if (isAll) {
      props.isAll = true
    } else {
      props.discipline = Number(currentDiscipline?.id)
    }

    const response = await getProgramContentResource(props)

    if (!response.success || !response.data || !response.data.resource) {
      setStateMachine(ComponentState.NOT_FOUND)
      return
    }

    setResource(response.data.resource)
    setStateMachine(ComponentState.READY)
  }

  const NotFoundState = () => (
    <>
      <Grid container alignItems='center' justifyContent='space-between' paddingBottom={theme.spacingStack.md}>
        <EmptyStateComponent
          ErrorAvatar={<CloeAvatar />}
        >
          <Grid container flexDirection='column' alignItems='center'>
            <Text2
              fontSize='lg'
              fontWeight='semibold'
              lineHeight='xs'
              mobile='lg'
              customColor={theme.colorBrand.medium}
            >
              {t('Conteúdo em preparação')}
            </Text2>
            <Text2
              fontSize='sm'
              fontWeight='medium'
              lineHeight='xs'
              mobile='sm'
              customColor={theme.colors.neutral.dark30}
            >
              {t('Em breve, ele estará disponível aqui.')}
            </Text2>
          </Grid>
        </EmptyStateComponent>
      </Grid>
    </>
  )

  const renderStates = {
    IDLE: <></>,
    LOADING: <div style={{ width: '100%', marginTop: theme.spacingStack.md }}><Loading type='linear' /></div>,
    ERROR: <Grid container alignItems='center' justifyContent='space-between' ><BusinessError error={t('Não foi possível exibir o conteúdo')?.toString()} /></Grid>,
    NOT_FOUND: <NotFoundState />,
    READY: resource ? <Resource resource={resource} /> : <></>
  }

  if (!taughtDisciplines || !taughtDisciplines.length || !segmentDisciplines.length) {
    return (
      <Grid container className={classes.containerWrapper}>
        <div style={{ width: '100%' }}><Loading type='linear' /></div>
      </Grid>
    )
  }

  const cloeProgramEnable = hasFeatureFlag(ToggleFeaturesEnum.cloeProgram)

  return (
    <Grid container className={classes.containerWrapper}>
      <Grid container>
        <Grid container direction='column'>
          <Text2
            fontSize='xl'
            fontWeight='medium'
            lineHeight='xs'
            mobile='xl'
            customColor={theme.colorBrand.medium}
          >
            {cloeProgramEnable ? t('Programa Cloe') : t('Conteúdo programático')}
          </Text2>
        </Grid>

        <Grid item xs={12}>
          <Text2
            fontSize='sm'
            fontWeight='medium'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark10}
          >
            <Trans i18nKey='ProgramContentParagraph'>
              Selecione o segmento e o componente curricular desejados para visualizar e baixar os conteúdos programáticos resumidos.
              Para conhecer a proposta pedagógica da Cloe, consulte <a target='_blank' href={pedagogicMapLink} className={classes.externalLink}>Mapa Pedagógico</a>.
            </Trans>
          </Text2>
        </Grid>

        <Grid container direction='column' marginTop={2.5} xs={12} className={classes.selectorContainer} >
          <Text2
            fontSize='sm'
            fontWeight='bold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark20}
          >
            {t('Segmento')}:
          </Text2>

          <Segments disabled={stateMachine === ComponentState.LOADING} taughtDisciplines={taughtDisciplines} selected={gradeTypeSelected} onChange={setGradeTypeSelected} dataTestid='program_content_select_segment' />
        </Grid>

        <Grid container direction='column' xs={12} className={classes.selectorContainer} >
          <Text2
            fontSize='sm'
            fontWeight='bold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark20}
          >
            {t('Componente curricular')}:
          </Text2>

          <Disciplines disabled={stateMachine === ComponentState.LOADING} dataTestid='program_content_select_curricular_component' disciplines={disciplines} currentDiscipline={currentDiscipline} onChange={handleDisciplineChange} showAll showAlertOnError />
        </Grid>

        <Grid container direction='row' xs={12} alignItems='center' gap={theme.spacingInline.xxxs} justifyContent='space-between' className={classes.selectorContainer} >
          <Text2
            fontSize='lg'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='lg'
            customColor={theme.colorBrand.medium}
          >
            {t('Programa Cloe')}
          </Text2>

          <Button
            fullWidth={isMobile}
            startIcon={<DownloadOutlined />}
            variant={'primary'}
            onClick={() => handlePDFDownload()}
            data-testid='program_content_select_download'
            className={classes.pdfDownloadButton}
            disabled={stateMachine === ComponentState.NOT_FOUND}
          >
            {t('Baixar PDF')}
          </Button>
        </Grid>

        <Grid container className={classes.contentWrapper}>
          {renderStates[stateMachine]}
        </Grid>
      </Grid>
    </Grid>
  )
}
