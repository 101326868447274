import { useEffect } from 'react'
import { Tabs, Tab, TabPanel } from 'components/design-system'
import { useParams, Link, LinkProps, Switch, Route } from 'react-router-dom'
import { SCHOOL_PROGRAM_LIST, PROGRAM_MANAGEMENT, CLOE_PROGRAM_LIST, CONTENT_MANAGEMENT } from 'navigation/CONSTANTS'
import { TabProps } from '@material-ui/core/Tab'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import { useAtom } from 'jotai'
import { atomProfileTab } from './atomStore'
import {
  SchoolProgramList,
  CloeProgramList,
  ProgramManagement,
  ProgramContent
} from './components'

import makeStyles from './style'
import { ToggleFeaturesEnum } from 'components/feature-toggle/enabledFeatures'
import { hasFeatureFlag } from 'components/feature-toggle/store'

interface TabType {
  label: string
  link: string
  icon: React.ReactNode
  dataTestid?: string
}

interface IProfileTabs {
  tab?: string
}

const cloeProgramEnable = hasFeatureFlag(ToggleFeaturesEnum.cloeProgram)

const tabs: TabType[] = [
  { label: cloeProgramEnable ? 'Programa Cloe' : 'Conteúdo programático', link: 'program-content', icon: <RouteOutlinedIcon />, dataTestid: 'menu_program_management_program_content' },
  { label: 'Gestão de programas', link: 'program-management', icon: <InventoryOutlinedIcon />, dataTestid: 'menu_program_management_program_management' }
]

export const ContentManagement: React.FC = () => {
  const classes = makeStyles()
  // hooks
  const { tab: tabParam } = useParams<IProfileTabs>()

  const [profileTab, setProfileTab] = useAtom(atomProfileTab)

  const LinkTab: React.ComponentType<TabProps & LinkProps> = Tab as React.ComponentType<TabProps & LinkProps>

  const handleCurrentProfileTab = (_event: React.SyntheticEvent, newValue: number) => {
    setProfileTab(newValue)
    const getPositionLink = tabs[newValue].link
    window.history.replaceState(null, '', CONTENT_MANAGEMENT(getPositionLink))
  }

  useEffect(() => {
    const getTab = tabParam ?? undefined
    if (getTab) {
      const index = tabs.findIndex(f => f.link === getTab)
      setProfileTab(index)
    } else {
      const getPositionLink = tabs[0].link
      window.history.replaceState(null, '', CONTENT_MANAGEMENT(getPositionLink))
    }
  }, [])

  return (
    <>
      <Tabs className={classes.tabs} value={profileTab} onChange={handleCurrentProfileTab}>
        {
          tabs.map((tab, index) =>
            <LinkTab
              component={Link}
              to={CONTENT_MANAGEMENT(tab.link)}
              key={index}
              label={<div>{tab.icon}<span>{tab.label}</span></div>}
              data-testid={tab.dataTestid}
            />
          )
        }
      </Tabs>
      <TabPanel value={profileTab} index={0}>
        <div className={classes.tabPanel}>
          <ProgramContent />
        </div>
      </TabPanel>
      <TabPanel value={profileTab} index={1}>
        <div className={classes.tabPanel}>
          <Switch>
            <Route exact path={PROGRAM_MANAGEMENT}>
              <ProgramManagement />
            </Route>
            <Route exact path={SCHOOL_PROGRAM_LIST}>
              <SchoolProgramList />
            </Route>
            <Route exact path={CLOE_PROGRAM_LIST}>
              <CloeProgramList />
            </Route>
          </Switch>
        </div>
      </TabPanel>
    </>
  )
}
